import { useEffect, useState, createContext, useContext } from 'react';
import {
  useUser as useSupaUser,
  useSessionContext,
  User
} from '@supabase/auth-helpers-react';
import { UserDetails } from '@/types';

type UserContextType = {
  accessToken: string | null;
  user: User | null;
  userDetails: UserDetails | null;
  isLoading: boolean;
  plan: string | null;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

interface Props {
  [propName: string]: any;
}

const MyUserContextProvider = (props: Props) => {
  const {
    session,
    isLoading: isLoadingUser,
    supabaseClient: supabase
  } = useSessionContext();
  const user = useSupaUser();
  const accessToken = session?.access_token ?? null;
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [plan, setPlan] = useState<string | null>(null);

  const getUserDetails = () => supabase.from('users').select('*').single();
  const getPricingPlan = (productId: string) =>
    supabase
      .from('pricing')
      .select('name')
      .eq('stripe_product_id', productId)
      .single();

  const storageClient = supabase.storage.from(`${user?.id}`);

  const updateUserDetails = () => {
    getUserDetails().then(({ data, error }) => {
      if (!error && data) {
        setUserDetails({ ...data, product_id: data?.plan } as UserDetails);

        if (data.subscription_status === 'active') {
          getPricingPlan(data.plan).then(
            ({ data: planData, error: planError }) => {
              if (!planError && planData) {
                setPlan(planData.name);
              }
              setIsLoadingData(false);
            }
          );
        } else {
          setPlan(null);
          setIsLoadingData(false);
        }
      } else {
        setIsLoadingData(false);
      }
    });
  };

  useEffect(() => {
    if (user && !isLoadingData && !userDetails) {
      setIsLoadingData(true);
      updateUserDetails();
    } else if (!user && !isLoadingUser && !isLoadingData) {
      setUserDetails(null);
    }

    if (user) {
      const channel = supabase
        .channel('value-db-changes')
        .on(
          'postgres_changes',
          {
            event: 'UPDATE',
            schema: 'public',
            table: 'users'
          },
          (payload) => {
            if (payload.new.id === user.id) {
              updateUserDetails();
            }
          }
        )
        .subscribe();

      return async () => {
        await supabase.removeChannel(channel);
      };
    }
    return () => {};
  }, [user, isLoadingUser]);

  const value = {
    accessToken,
    user,
    userDetails,
    isLoading: isLoadingUser || isLoadingData,
    storageClient,
    plan
  };

  return <UserContext.Provider value={value} {...props} />;
};

const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a MyUserContextProvider.');
  }
  return context;
};

export { MyUserContextProvider, useUser };
