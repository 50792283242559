import { useSupabaseClient } from '@supabase/auth-helpers-react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Mixpanel } from '@/analytics/browser';
import Logo from '@/components/icons/logo';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import { useUser } from '@/hooks/use-user';
import { ExternalLinkIcon, PencilIcon } from 'lucide-react';
import { useEffect } from 'react';
import { Badge } from './badge';
import CreditCount from './credit-count';

const Navbar = () => {
  const router = useRouter();
  const supabaseClient = useSupabaseClient();
  const { user, userDetails, plan } = useUser();

  useEffect(() => {
    if (user) {
      Mixpanel.identify(user.id);
      Mixpanel.people.set({
        distinct_id: user.id,
        user_id: user.id
      });
    }
  }, [user]);

  const totalCredits =
    Number(userDetails?.credits) + Number(userDetails?.addon_credits) || 0;
  function AuthedNavBar() {
    return (
      <ul className="flex flex-1 justify-end items-center space-x-6">
        <li className="hidden md:block">
          <Link href="/projects/new" aria-label="Create">
            <Button variant="ghost" className="flex space-x-1" size="sm">
              <PencilIcon size={16} /> <span>Create</span>
            </Button>
          </Link>
        </li>
        {plan === null ? (
          <Link href="/pricing" aria-label="Upgrade">
            <button className="fancy-button">
              <div className="button-wrapper">
                <span className="font-bold fancy-button-text text-slate-200">
                  Upgrade
                </span>
                <div className="circle circle-12"></div>
                <div className="circle circle-11"></div>
                <div className="circle circle-10"></div>
                <div className="circle circle-9"></div>
                <div className="circle circle-8"></div>
                <div className="circle circle-7"></div>
                <div className="circle circle-6"></div>
                <div className="circle circle-5"></div>
                <div className="circle circle-4"></div>
                <div className="circle circle-3"></div>
                <div className="circle circle-2"></div>
                <div className="circle circle-1"></div>
              </div>
            </button>
          </Link>
        ) : (
          <p>{plan}</p>
        )}
        <li className="items-center space-x-1">
          <CreditCount count={totalCredits} />
        </li>
        <li>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Avatar className="cursor-pointer">
                <AvatarImage src={user?.user_metadata.avatar_url} />
                <AvatarFallback>
                  {user?.user_metadata.full_name.split(' ')[0].substr(0, 2)}
                </AvatarFallback>
              </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start" forceMount>
              <DropdownMenuLabel className="opacity-80">
                My Account
              </DropdownMenuLabel>
              <DropdownMenuLabel className="inline-block md:hidden lg:hidden">
                Plan <Badge className="ml-2 rounded-md">{plan}</Badge>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <Link href="/pricing">
                <DropdownMenuItem className="text-slate-400">
                  Pricing {/* hide it for screen other than mobile */}
                </DropdownMenuItem>
              </Link>
              <Link href="/account">
                <DropdownMenuItem>Profile</DropdownMenuItem>
              </Link>
              <DropdownMenuSeparator />
              <Link
                href="https://woolen-digestion-6a4.notion.site/CTR-Hero-FAQs-3e3120843e284f06bb2d1f9f01f4e64d"
                target="_blank"
              >
                <DropdownMenuItem>
                  FAQs
                  <ExternalLinkIcon size={12} className="ml-2" />
                </DropdownMenuItem>
              </Link>
              <Link href="https://alphactr.com/terms" target="_blank">
                <DropdownMenuItem>
                  T&C
                  <ExternalLinkIcon size={12} className="ml-2" />
                </DropdownMenuItem>
              </Link>
              <Link href="https://alphactr.com/privacy-policy" target="_blank">
                <DropdownMenuItem>
                  Privacy Policy
                  <ExternalLinkIcon size={12} className="ml-2" />
                </DropdownMenuItem>
              </Link>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                onClick={async () => {
                  await supabaseClient.auth.signOut();
                  await router.push('/signin');
                }}
              >
                Sign out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </li>
      </ul>
    );
  }

  function UnAuthedNavBar() {
    return (
      <ul className="flex flex-1 justify-end items-center space-x-8">
        <li>
          <Link href="/signin" aria-label="Sign In">
            Sign In
          </Link>
        </li>
      </ul>
    );
  }

  return (
    <nav className="z-99 h-14">
      <div>
        <div className="flex justify-between align-center flex-row py-2 px-4 relative">
          {/* Logo */}
          <div className="flex flex-1 items-center space-x-4">
            <Link href="/" aria-label="Logo">
              <img src="/logo.png" alt="AlphaCTR" width={128} />
            </Link>
            {user && router.pathname !== '/' && (
              <Link href="/" aria-label="Dashboard Button">
                <Button variant="ghost">Dashboard</Button>
              </Link>
            )}
          </div>

          {user ? <AuthedNavBar /> : <UnAuthedNavBar />}
        </div>

        <Separator />
      </div>
    </nav>
  );
};

export default Navbar;
