import Head from 'next/head';
import { PropsWithChildren } from 'react';
import { PageMeta } from '../types';
import Navbar from '@/components/ui/navbar';
import { useRouter } from 'next/router';
import clsx from 'clsx';

interface Props extends PropsWithChildren {
  meta?: PageMeta;
}

export default function Layout({ children, meta: pageMeta }: Props) {
  const meta = {
    title: 'AlphaCTR',
    description: 'Augment your youtube thumbnails with AI.',
    ...pageMeta
  };

  const router = useRouter();

  const mainContentStyle = clsx(
    !router.pathname.includes('editor') && 'py-4',
    'min-w-full'
  );

  return (
    <>
      <Head>
        <title>{meta.title}</title>
        <meta name="robots" content="follow, index" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link href="/favicon.ico" rel="shortcut icon" />
        <meta content={meta.description} name="description" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:image" content={meta.cardImage} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@vercel" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.cardImage} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1 maximum-scale=1"
        />
      </Head>
      <div className="text-slate-50 bg-gradient-to-br bg-brand-950 flex flex-col min-h-screen">
        <Navbar />
        <main id="skip" className={mainContentStyle}>
          {children}
        </main>
      </div>
    </>
  );
}
