import mixpanel, { Dict } from 'mixpanel-browser';

const IS_PROD = process.env.NODE_ENV === 'production';

mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string);

const noopMixpanel = {
  identify: (id: string) => {},
  alias: (id: string) => {},
  track: (name: string, props?: Dict) => {},
  time_event: (name: string) => {},
  people: {
    set: (props: Dict) => {}
  },
  trackV2: (name: string, props?: Dict) => {}
};

export const Mixpanel = IS_PROD
  ? {
      identify: (id: string) => {
        mixpanel.identify(id);
      },
      alias: (id: string) => {
        mixpanel.alias(id);
      },
      track: (name: string, props?: Dict) => {
        mixpanel.track(name, props);
      },
      trackV2: (name: string, props?: Dict) => {
        mixpanel.track(name, { ...props, version: 2 });
      },
      time_event: (name: string) => {
        mixpanel.time_event(name);
      },
      people: {
        set: (props: Dict) => {
          mixpanel.people.set(props);
        }
      }
    }
  : noopMixpanel;
