import { useEffect, useState } from 'react';
import { AppProps } from 'next/app';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { createBrowserSupabaseClient } from '@supabase/auth-helpers-nextjs';

import { ThemeProvider } from 'next-themes';
import { MyUserContextProvider } from '@/hooks/use-user';
import type { Database } from 'types_db';
import 'styles/main.css';
import 'styles/chrome-bug.css';
import Layout from '@/components/layout';
import localFont from 'next/font/local';
import { Toaster } from '@/components/ui/toaster';
import usePageViewTracking from '@/hooks/use-page-view-tracking';

const Satoshi = localFont({
  src: '../public/fonts/font-satoshi.ttf',
  preload: true,
  variable: '--satoshi--font'
});

import dynamic from 'next/dynamic';

const CrispWithNoSSR = dynamic(() => import('../components/crisp'), {
  ssr: false
});

export default function MyApp({ Component, pageProps }: AppProps) {
  usePageViewTracking();

  const [supabaseClient] = useState(() =>
    createBrowserSupabaseClient<Database>()
  );
  useEffect(() => {
    document.body.classList?.remove('loading');
    localStorage.theme = 'dark';
  }, []);

  return (
    <>
      <style jsx global>{`
				:root {
          --satoshi-font: ${Satoshi.style.fontFamily};
				}
			}`}</style>
      <div>
        <SessionContextProvider supabaseClient={supabaseClient}>
          <MyUserContextProvider>
            <ThemeProvider attribute="class" defaultTheme="dark">
              <Layout>
                <Component {...pageProps} />
                <Toaster />
              </Layout>
            </ThemeProvider>
          </MyUserContextProvider>
        </SessionContextProvider>
        <CrispWithNoSSR />
      </div>
    </>
  );
}
