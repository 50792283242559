import * as React from 'react';
import { VariantProps, cva } from 'class-variance-authority';

import { cn } from '@/styles/style';

const badgeVariants = cva(
  'inline-flex items-center border rounded-full px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'bg-brand-100 hover:bg-persian-400/80 border-transparent text-slate-700',
        secondary:
          'bg-storm-400 hover:bg-storm-400/80 border-transparent text-storm-700',
        destructive:
          'bg-red-400 hover:bg-red-400/80 border-transparent text-red-700',
        outline: 'text-foreground'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
